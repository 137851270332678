import moment from "moment";
import React, { Component, useEffect, useState } from "react";
import { Button, Input, Row, CardBody } from "reactstrap";
import axios from "axios";
import swal from "sweetalert";
import { registerLocale } from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import { format, parse, parseISO } from "date-fns";
import InputMask from "react-input-mask";
import CharacterRemover from "character-remover";
import validator from "validator";
import { URL_PreScheduling } from "../Services/preSchedulingService";
import { FaCheck } from "react-icons/fa";
import { Spinner } from "../../common/components/spinner/spinner";
import { useNavigate } from "react-router-dom";
import { validate } from "gerador-validador-cpf";
import { FaChevronLeft } from "react-icons/fa";
import {
  CustomConfirmationModal,
  CustomModal,
  PaymentAlternativeModal,
} from "../../common/components/utils/customModal";
import characterRemover from "character-remover";

class Scheduling extends Component {
  constructor(props) {
    super(props);
    registerLocale("br", br);
    const myParam = window.location.pathname.split("/")[1];

    this.state = {
      model: {
        name: "",
        telephone: "",
        email: "",
        date: "",
        idCompanyProvider: 0,
        nameProvider: "",
        note: "",
        birthDate: "",
        document: "",
        servicePrice: 0,
      },
      errors: {},
      modelClinic: {},
      listSchedulings: [],
      myParam: myParam,
      listProcedures: [],
      loading: false,
      loadingSearchClient: false,
      redirect: false,
      paymentRequired: false,
      payment: false,
      paymentType: 0,
      cpfRequired: false,
      TypeOfRedirect: 0,
      returnPost: {},
      signalPercentage: 0,
    };
  }

  validateFields = () => {
    const { model, paymentRequired, fields, payment } = this.state;
    const errors = {};
    let isError = 0;
    let phone = CharacterRemover.removeAll(model.telephone);
    let document = CharacterRemover.removeAll(model.document);
    let email = model.email != null ? validator.isEmail(model.email) : null;
    var result = parse(model.birthDate, "dd/MM/yyyy", new Date());
    var dateRegExp = /\d{2}\/\d{2}\/\d{4}/g
    if (!model.name) {
      isError++;
      errors.nameError = true;
    } else errors.nameError = false;
    if (!phone || phone?.length < 11) {
      isError++;
      errors.telephoneError = true;
    } else errors.telephoneError = false;

    if ((!!fields.emailRequired) && (email == false)) {
      isError++;
      errors.emailError = true;
    } else errors.emailError = false;

    if (
      (paymentRequired == true || fields.cpfRequired == true || payment == true) &&
      validate(document) == false
    ) {
      isError++;
      errors.documentError = true;
    } else errors.documentError = false;

    if ((!!fields.birthDateRequired) && (!dateRegExp.test(model.birthDate) && result == "Invalid Date")) {
      isError++;
      errors.birthDateError = true; 
    } else {
      errors.birthDateError = false;
    }
    
    this.setState({
      errors: errors,
    });
    return isError;
  };

  setValues = async (e, field) => {
    const { model } = this.state;
    if (field == "date") model[field] = e;
    else model[field] = e.target.value;
    if (field == "telephone") {
      const tel = characterRemover.removeAll(e.target.value);
      if (tel && tel.length == 11) this.consultClient();
    }
    this.setState({ model });
    this.validateFields();
  };

  clearModel = () => {
    localStorage.clear();
    this.setState({
      redirect: true,
      returnPost: {},
      loading: false,
    });
  };

  toSchedule = async () => {
    if (this.validateFields() == 0) {
      this.setState({ loading: true });
      const { model, myParam, listProcedures } = this.state;
      const tel = CharacterRemover.removeAll(model.telephone);
      let map = {
        idCompanyProvider: model.idCompanyProvider,
        name: model.name,
        telephone: tel,
        email: model.email,
        date: format(new Date(model.date), "yyyy-MM-dd HH:mm"),
        note: model.note,
        preSchedulingServiceProvideds: listProcedures,
        consultationTime: listProcedures.reduce(
          (partialSum, a) => partialSum + a.duration,
          0
        ),
        birthDate:
          model.birthDate == ""
            ? null
            : parse(model.birthDate, "dd/MM/yyyy", new Date()),
        document: CharacterRemover.removeAll(model.document),
        createdDate: format(moment().toDate().getTime(), "yyyy-MM-dd HH:mm"),
      };

      await axios
        .post(`${URL_PreScheduling}/${myParam}`, map)
        .then(async (resp) => {
          const { data } = resp;

          if (data.message == "Salvo com sucesso!") {
            if (
              data.procedureClient != undefined &&
              data.procedureClient.length > 0
            ) {
              this.setState({ returnPost: data });
            } else {
              this.setState({ loading: false });
              this.clearModel();
            }
          } else {
            swal({
              icon: "error",
              text: "Horário indisponível!",
            }).then((resp) => {
              this.setState({ loading: false });
            });
          }
        })
        .catch(() => this.setState({ loading: false }));
    }
  };

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentDidMount = () => {
    const { model } = this.state;
    let list = JSON.parse(localStorage.getItem("listProcedures"));
    model.idCompanyProvider = JSON.parse(
      localStorage.getItem("providerSelected")
    )["idCompanyProvider"];
    model.nameProvider = JSON.parse(localStorage.getItem("providerSelected"))[
      "nameProvider"
    ];
    let payment = JSON.parse(localStorage.getItem("dataClinic"))["payment"];
    let paymentRequired = JSON.parse(localStorage.getItem("dataClinic"))[
      "paymentRequired"
    ];
    let paymentType = JSON.parse(localStorage.getItem("dataClinic"))[
      "paymentType"
    ];
    let signalPercentage = JSON.parse(localStorage.getItem("dataClinic"))[
      "signalPercentage"
    ];
    // let CpfRequired =
    //   localStorage.getItem("CpfRequired") == "true" ? true : false;
    const fields = JSON.parse(localStorage.getItem('fields'));
    model.date = localStorage.getItem("dateTimeSelected") ?? "";
    let TypeOfRedirect = localStorage.getItem("TypeOfRedirect");
    this.setState({
      model: model,
      listProcedures: list,
      paymentRequired: paymentRequired,
      // cpfRequired: CpfRequired,
      fields,
      TypeOfRedirect: TypeOfRedirect,
      paymentType: paymentType,
      payment: payment,
      signalPercentage: signalPercentage,
    });
  };

  consultClient = async () => {
    const { model, myParam } = this.state;
    this.setState({ loadingSearchClient: true });
    const tel = CharacterRemover.removeAll(model.telephone);

    if (tel && tel.length >= 11) {
      await axios
        .get(`${URL_PreScheduling}/GetClientByPhone`, {
          params: {
            aliasClinic: myParam,
            phone: tel,
          },
        })
        .then((resp) => {
          const { data } = resp;

          if (data.id > 0) {
            model.telephone = data.cellPhone;
            model.name = data.name;
            model.document = data.cpf;
            model.birthDate = moment(data.birthDate).format("DD/MM/YYYY");
            model.email = data.email;
            model.idClient = data.id;
            this.setState({ model });
          }
        })
        .catch(() => {
          this.setState({ loadingSearchClient: false });
        });
    }
    await this.SerchBalancePackage();
    await this.SerchBalanceSubscriptionPlan();
    this.setState({ loadingSearchClient: false });
    this.validateFields();
  };

  //busca saldo pacote
  SerchBalancePackage = async () => {
    const { model, myParam, listProcedures } = this.state;
    if (listProcedures.length > 0) {
      await axios
        .get(`${URL_PreScheduling}/GetBalancePackage`, {
          params: {
            idClient: model.idClient,
            alias: myParam,
            procedures: JSON.stringify(listProcedures),
          },
        })
        .then((r) => {
          if (r.data.length > 0) {
            //desativar pagamentos zerando o valor
            r.data.forEach((element) => {
              
              if (element.balance > 0|| element.amountIllimited) {
                  listProcedures.map(e=>{
                    if(element.idProcedure==e.idProcedure){
                    e.value=0
                  }
                })
              }
            });

            const balance = listProcedures.reduce(
              (partialSum, a) => partialSum + a.value,
              0);
            if (balance == 0) {
              this.setState({
                paymentRequired: false,
                payment: false,
                listProcedures: listProcedures,
              });
            } else {
              this.setState({
                listProcedures: listProcedures,
              });
            }
          }
        });
    }
  };

  //busca saldo planos
  SerchBalanceSubscriptionPlan = async () => {
    const { model, myParam, listProcedures } = this.state;
    if (listProcedures.length > 0) {
      await axios
        .get(`${URL_PreScheduling}/GetBalanceSubscriptionPlan`, {
          params: {
            idClient: model.idClient,
            alias: myParam,
            procedures: JSON.stringify(listProcedures),
          },
        })
        .then((r) => {
          if (r.data.length > 0) {
            //desativar pagamentos zerando o valor
            r.data.forEach((element) => {
              
              if (element.balance > 0|| element.amountIllimited) {
                  listProcedures.map(e=>{
                    if(element.idProcedure==e.idProcedure){
                    e.value=0
                  }
                })
              }
            });

            const balance = listProcedures.reduce(
              (partialSum, a) => partialSum + a.value,
              0);
            if (balance == 0) {
              this.setState({
                paymentRequired: false,
                payment: false,
                listProcedures: listProcedures,
              });
            } else {
              this.setState({
                listProcedures: listProcedures,
              });
            }
          }
        });
    }
  };

  render() {
    const {
      returnPost,
      model,
      errors,
      loadingSearchClient,
      loading,
      paymentRequired,
      payment,
      paymentType,
      fields,
      myParam,
      redirect,
      listProcedures,
      TypeOfRedirect,
      signalPercentage,
    } = this.state;

    return (
      <div>
        {returnPost.procedureClient != undefined &&
          returnPost.procedureClient.length > 0 ? (
          <RedeemPointsFunction
            clearModel={this.clearModel}
            returnPost={returnPost}
          />
        ) : null}
        {redirect && (
          <Redirect
            myParam={myParam}
            tel={model.telephone}
            TypeOfRedirect={TypeOfRedirect}
          />
        )}
        {loadingSearchClient && <Spinner />}
        {loading && <Spinner />}
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            alignSelf: "stretch",
            marginBottom: "24px",
          }}
        >
          <button
            style={{
              display: "flex",
              minHeight: "40px",
              maxHeight: "40px",
              width: "40px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              borderRadius: "4px",
              border: "1px",
              paddingTop: "9px",
              paddingRight: "16px",
              paddingBottom: "9px",
              paddingLeft: "16px",
              backgroundColor: "transparent",
            }}
            onClick={() => window.history.back()} // Replace with your actual event handler
          >
            <FaChevronLeft style={{ color: "171821", width: "14px", height: "14px" }} />
          </button>
          <div
            style={{
              fontFamily: "inter, sans-serif",
              color: "rgb(23, 24, 33)",
              flex: "1 0 0px",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
            }}
          >
            Para agendar, preencha-os campos
          </div>{" "}
        </div>
        <div className="card-b">
          <div
            style={{
              paddingTop: "12px",
              paddingRight: "8px",
              paddingBottom: "12px",
              paddingLeft: "8px",
              gap: "8px",
              alignSelf: "stretch",
              borderRadius: "4px",
              border: "1px solid var(--Color-Gray-100, #E3E3E3)",
              backgroundColor: "#FBFBFB",
              marginBottom: "8px",
            }}
          >
            <div
              style={{
                textAlign: "center",
                color: "#171821",
                fontFamily: "inter,sans-serif",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
              }}
            >
              {model.date !== ""
                ? `${format(new Date(model.date), "dd/MM/yyyy", {
                  locale: br,
                })} às ${format(new Date(model.date), "HH:mm", {
                  locale: br,
                })} com ${model.nameProvider}`
                : ""}
            </div>
            {listProcedures.map((e, index) => (
              <div
                key={index}
                style={{
                  paddingTop: "12px",
                  alignSelf: "stretch",
                  textAlign: "center",
                  color: "#171821",
                  fontFamily: "inter,sans-serif",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "normal",
                }}
              >
                {e.description} -{" "}
                {e.value.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </div>
            ))}
          </div>

          <div
            style={{
              display: "flex",
              gap: "12px",
              justifyContent: "center",
              alignItems: "flex-start",
              alignSelf: "stretch",
              marginBottom: "8px",
            }}
          >
            <div
              style={{
                height: "60px",
                flexDirection: "column",
                alignItems: "center",
                gap: "4px",
                flex: "1 0 0",
              }}
            >
              <label
                style={{
                  height: "20px",
                  paddingTop: "2px",
                  paddingBottom: "1px",
                  justifyContent: "left",
                  alignItems: "center",
                  flexShrink: "0",
                  alignSelf: "stretch",
                  fontFamily: "inter,sans-serif",
                  fontSize: "14px",
                }}
              >
                Telefone
              </label>
              <Input
                style={{
                  minHeight: "36px",
                  maxHeight: "36px",
                  padding: "6px 8px",
                  alignItems: "center",
                  alignSelf: "stretch",
                  fontSize: "14px",
                }}
                type="text"
                mask="(99) 9 9999-9999"
                tag={InputMask}
                onChange={(e) => {
                  this.setValues(e, "telephone");
                }}
                value={model.telephone}
                placeholder="(00) 0 0000-0000"
                invalid={errors.telephoneError}
              />
            </div>
            <div
              style={{
                height: "60px",
                flexDirection: "column",
                alignItems: "center",
                gap: "4px",
                flex: "1 0 0",
              }}
            >
              <label
                style={{
                  fontFamily: "inter,sans-serif",
                  height: "20px",
                  paddingTop: "2px",
                  paddingBottom: "1px",
                  justifyContent: "left",
                  alignItems: "center",
                  flexShrink: "0",
                  alignSelf: "stretch",
                  fontSize: "14px",
                }}
              >
                Nome
              </label>
              <Input
                style={{
                  minHeight: "36px",
                  maxHeight: "36px",
                  padding: "6px 8px",
                  alignItems: "center",
                  gap: "8px",
                  alignSelf: "stretch",
                  fontSize: "14px",
                }}
                type="text"
                onChange={(e) => this.setValues(e, "name")}
                value={model.name ?? ""}
                placeholder="Digitar..."
                invalid={errors.nameError}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex", // Alinhamento em linha
              gap: "12px", // Espaçamento de 12px entre os inputs
              justifyContent: "center",
              alignItems: "flex-start",
              alignSelf: "stretch",
              marginBottom: "8px",
            }}
          >
            {!!fields?.showBirthDate &&
              <div
                style={{
                  height: "60px",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "4px",
                  flex: "1 0 0",
                }}
              >
                <label
                  style={{
                    height: "20px",
                    paddingTop: "2px",
                    paddingBottom: "1px",
                    justifyContent: "left",
                    alignItems: "center",
                    flexShrink: "0",
                    alignSelf: "stretch",
                    fontFamily: "inter,sans-serif",
                    fontSize: "14px",
                  }}
                >
                  Data de Nascimento
                </label>
                <Input
                  style={{
                    minHeight: "36px",
                    maxHeight: "36px",
                    padding: "6px 8px",
                    alignItems: "center",
                    alignSelf: "stretch",
                    fontSize: "14px",
                  }}
                  type="text"
                  mask="99/99/9999"
                  tag={InputMask}
                  value={model.birthDate ?? ""}
                  onChange={(e) => this.setValues(e, "birthDate")}
                  invalid={errors.birthDateError}
                  placeholder="dd/mm/aaaa"
                />
              </div>}

            {(paymentRequired == true ||
              fields?.showCpf == true ||
              payment == true) && (
                <div
                  style={{
                    height: "60px",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "2px",
                    flex: "1 0 0",
                  }}
                >
                  <label
                    style={{
                      height: "20px",
                      paddingTop: "2px",
                      paddingBottom: "1px",
                      justifyContent: "left",
                      alignItems: "center",
                      flexShrink: "0",
                      alignSelf: "stretch",
                      fontFamily: "inter,sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    CPF
                  </label>
                  <Input
                    style={{
                      minHeight: "36px",
                      maxHeight: "36px",
                      padding: "6px 8px",
                      alignItems: "center",
                      alignSelf: "stretch",
                      fontSize: "14px",
                    }}
                    type="text"
                    onChange={(e) => this.setValues(e, "document")}
                    value={model.document}
                    mask="999.999.999-99"
                    tag={InputMask}
                    invalid={errors.documentError}
                    placeholder="000.000.000-00"
                  />
                </div>
              )}
          </div>

          {!!fields?.showEmail &&
            <Row
              style={{
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "12px",
                alignSelf: "stretch",
                marginBottom: "8px",
              }}
            >
              <div
                className="col-md-6"
                style={{
                  height: "60px",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "4px",
                  flex: "1 0 0",
                }}
              >
                <label
                  style={{
                    height: "20px",
                    paddingTop: "2px",
                    paddingBottom: "1px",
                    justifyContent: "left",
                    alignItems: "center",
                    flexShrink: "0",
                    alignSelf: "stretch",
                    fontFamily: "inter,sans-serif",
                    fontSize: "14px",
                  }}
                >
                  E-mail
                </label>
                <Input
                  style={{
                    // display: 'flex',
                    minHeight: "36px",
                    maxHeight: "36px",
                    padding: "6px 8px",
                    alignItems: "center",
                    gap: "8px",
                    alignSelf: "stretch",
                    fontSize: "14px",
                  }}
                  type="email"
                  value={model.email}
                  invalid={errors.emailError}
                  onChange={(e) => this.setValues(e, "email")}
                  placeholder="Digitar..."
                />
              </div>
            </Row>}
          <Row
            style={{
              justifyContent: "center",
              alignItems: "flex-start",
              gap: "12px",
              alignSelf: "stretch",
            }}
          >
            <div
              className="col-md-6"
              style={{
                height: "60px",
                flexDirection: "column",
                alignItems: "center",
                gap: "4px",
                flex: "1 0 0",
              }}
            >
              <label
                style={{
                  height: "20px",
                  paddingTop: "2px",
                  paddingBottom: "1px",
                  justifyContent: "left",
                  alignItems: "center",
                  flexShrink: "0",
                  alignSelf: "stretch",
                  fontFamily: "inter,sans-serif",
                  fontSize: "14px",
                }}
              >
                Observação
              </label>
              <Input
                style={{
                  // display: 'flex',
                  minHeight: "36px",
                  maxHeight: "36px",
                  padding: "6px 8px",
                  alignItems: "center",
                  gap: "8px",
                  alignSelf: "stretch",
                  fontSize: "14px",
                }}
                type="text"
                value={model.note}
                onChange={(e) => this.setValues(e, "note")}
                placeholder="Digitar..."
              />
            </div>
          </Row>

          {paymentRequired == false && payment == false ? (
            <Button
              style={{
                display: "flex",
                minHeight: "45px",
                maxHeight: "45px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                alignSelf: "center",
                gap: "8px",
                borderRadius: "4px",
                backgroundColor: "var(--Color-Primary-500, #171821)",
                marginTop: "16px",
                borderStyle: "none",
              }}
              size="lg"
              block
              onClick={(e) => this.toSchedule()}
              disabled={loading}
            >
              <span
                style={{
                  display: "flex",
                  //height: "45px",
                  //paddingTop: "10px",
                  //paddingRight: "24px",
                  //paddingBottom: "28px",
                  //paddingLeft: "24px",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  //alignSelf: "center",
                  //gap: "8px",
                  //alignSelf: "stretch",
                  //borderRadius: "6px",
                  fontWeight: "600",
                  fontSize: "16px",
                  fontFamily: "inter,sans-serif",
                }}
              >
                Agendar
              </span>
            </Button>
          ) : (
            <RedirectPayment
              model={model}
              listProcedures={listProcedures}
              myParam={myParam}
              validateFields={this.validateFields}
              payment={payment}
              paymentRequired={paymentRequired}
              paymentType={paymentType}
              signalPercentage={signalPercentage}
              toSchedule={this.toSchedule}
            />
          )}
        </div>
      </div>
    );
  }
}

export default class SchedulingClient extends Component {
  render() {
    return (
      <div>
        <Scheduling />
      </div>
    );
  }
}

function Redirect(props) {
  const navigate = useNavigate();

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const redirectHome = async () => {
    await delay(3000);
    navigate("/" + props.myParam, { replace: true });
  };

  useEffect(() => {
    redirectHome();
    return () => { };
  }, []);

  return (
    <CustomModal
      icon={<FaCheck />}
      isOpen={true}
      background="var(--Color-Green-25, #F2FFF2)"
      description={"Agendamento realizado!"}
    />
  );
}

function RedirectPayment(props) {
  let paymentType = props.paymentType;
  const navigate = useNavigate();
  const val = async () => {
    let value = props.listProcedures.reduce(
      (partialSum, a) => partialSum + a.value,
      0
    );
    let partialValue = false;
    if (props.paymentRequired) {
      if (
        paymentType == PaymentType.partial ||
        paymentType == PaymentType.alternative
      ) {
        const signalValue = value * (props.signalPercentage / 100);
        value = signalValue;
        partialValue = true;
      }
    }
    if (props.validateFields() == 0) {
      const tel = CharacterRemover.removeAll(props.model.telephone);
      let map = {
        idCompanyProvider: props.model.idCompanyProvider,
        name: props.model.name,
        telephone: tel,
        email: props.model.email,
        date: format(new Date(props.model.date), "yyyy-MM-dd HH:mm"),
        note: props.model.note,
        document: props.model.document,
        preSchedulingServiceProvideds: props.listProcedures,
        consultationTime: props.listProcedures.reduce(
          (partialSum, a) => partialSum + a.duration,
          0
        ),
        birthDate: props.model.birthDate == "" ? null : props.model.birthDate,
        document: CharacterRemover.removeAll(props.model.document),
        value: value,
        partialValue: partialValue,
      };
      localStorage.setItem("mapscheduling", JSON.stringify(map));
      navigate("/" + props.myParam + "/formpayment", { replace: false });
    }
  };
  const toogleModal = (res) => {
    if (res.confirm) {
      val();
    } else {
      setPaymentOptional(false);
      props.toSchedule();
    }
  };
  const toogleModalAlternative = (res) => {
    if (res.confirm) {
      paymentType = PaymentType.partial;
      val();
      setPaymentAlternative(false);
    } else {
      paymentType = PaymentType.total;
      val();
      setPaymentAlternative(false);
    }
  };

  const [paymentOptional, setPaymentOptional] = useState(false);
  const [paymentAlternative, setPaymentAlternative] = useState(false);
  const options = () => {
    if (props.validateFields() == 0) {
      if (props.paymentRequired == false && props.payment == true) {
        setPaymentOptional(true);
      } else if (
        props.paymentRequired == true &&
        props.paymentType == PaymentType.alternative
      ) {
        setPaymentAlternative(true);
      } else {
        setPaymentOptional(false);
        setPaymentAlternative(false);
        val();
      }
    }
  };

  return (
    <div style={{ marginTop: "16px" }}>
      {paymentAlternative && (
        <PaymentAlternativeModal
          description={"Deseja antecipar seu pagamento?"}
          toogleModal={toogleModalAlternative}
        />
      )}
      {paymentOptional && (
        <CustomConfirmationModal
          description={"Deseja antecipar seu pagamento?"}
          toogleModal={toogleModal}
        />
      )}
      <Button
        style={{
          display: "flex",
          minHeight: "45px",
          maxHeight: "45px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          alignSelf: "center",
          gap: "8px",
          borderRadius: "4px",
          backgroundColor: "var(--Color-Primary-500, #171821)",
          marginTop: "16px",
          borderStyle: "none",
        }}
        size="lg"
        block
        onClick={(e) => options()}
      >
        {
          <span
            style={{
              display: "flex",
              //height: "45px",
              //paddingTop: "10px",
              //paddingRight: "24px",
              //paddingBottom: "28px",
              //paddingLeft: "24px",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              //alignSelf: "center",
              //gap: "8px",
              //alignSelf: "stretch",
              //borderRadius: "6px",
              fontWeight: "600",
              fontSize: "16px",
              fontFamily: "inter,sans-serif",
            }}
          >
            Agendar
          </span>
        }
      </Button>
    </div>
  );
}

function RedeemPointsFunction(props) {
  const RedeemPoints = async () => {
    await axios
      .put(`${URL_PreScheduling}/RedeemPoints`, props.returnPost)
      .then((resp) => {
        const { data } = resp;
        if (data) {
          props.clearModel();
        }
      });
  };
  const toogleModal = async (r) => {
    if (r.confirm) {
      RedeemPoints();
    } else {
      props.clearModel();
    }
  };
  return (
    <CustomConfirmationModal
      toogleModal={toogleModal}
      description={"Pontuação atingida. Deseja Resgatar?"}
    />
  );
}

const PaymentType = {
  total: 0,
  partial: 1,
  alternative: 2,
};
